<template>
    <div class="modal-edit-style-type items-start bg-main">
        <div class="header" @click="submit">
            <i class="material-icons m-16">chevron_left</i>
        </div>
        <div class="body" ref="body">
            <div class="title f-medium m-l-20" v-html="getTitle" />
            <ul class="description-area m-20">
                <li
                    v-if="getDescription"
                    class="description c-grey-07 f-14 m-20"
                    style="list-style-type: disc; color: #666b70"
                    v-html="getDescription"
                />
                <li
                    v-if="getDescriptionSecond"
                    class="description c-danger f-14 m-20"
                    style="list-style-type: disc; color: $c-danger"
                    v-html="getDescriptionSecond"
                />
            </ul>
            <Slider
                v-if="options.key === 'age' && ageRange.length"
                ref="ageSlider"
                :range="ageRange"
                :myRange="ageValue"
                :newRange="newRange"
                :required="true"
                :slider-options="ageSliderOptions"
                :unit="'세'"
                :rangeDefault="'선택해주세요'"
                @change="updateStyleChanged"
            />
            <Slider
                v-else-if="options.key === 'height' && heightRange.length"
                ref="heightSlider"
                :title="'키'"
                :range="heightRange"
                :myRange="heightValue"
                :unit="'cm'"
                :required="true"
                :rangeDefault="'선택해주세요'"
                :slider-options="heightSliderOptions"
                @change="updateStyleChanged"
            />
            <div class="distance-slider" v-else-if="options.key === 'region'">
                <div class="slider-title m-l-20">내 집으로부터</div>
                <Slider
                    ref="houseSlider"
                    :title="'내 집으로부터'"
                    :value="houseValue"
                    :unit="'km'"
                    :required="true"
                    :rangeDefault="'선택해주세요'"
                    :slider-options="houseSliderOptions"
                    @change="updateStyleChanged"
                />
                <div class="slider-title m-l-20 m-t-20">내 직장으로부터</div>
                <Slider
                    ref="jobSlider"
                    :title="'내 직장으로부터'"
                    :value="jobValue"
                    :unit="'km'"
                    :required="true"
                    :rangeDefault="'선택해주세요'"
                    :slider-options="jobSliderOptions"
                    @change="updateStyleChanged"
                />
            </div>
            <!-- <div v-if="rangeTooNarrow" class="warn m-t-12 m-l-20 m-r-20">
                <div class="warn-image m-t-4">
                    <img :src="require(`@/assets/images/icons/warn.png`)" alt="warn" width="16px" />
                </div>
                <div class="warn-text f-14 f-medium m-l-12" v-html="warnDescription" />
            </div> -->
        </div>
        <!-- <BottomButton :label="$translate('SAVE')" @click="submit" /> -->
    </div>
</template>

<script>
export default {
    name: 'ModalEditStyleSlider',
    props: ['options'],
    data: () => ({
        styleChanged: false,
        ageValue: [],
        ageRange: [],
        newRange: [],
        houseRange: [],
        jobRange: [],
        houseValue: null,
        jobValue: null,
        heightRange: [],
        ageSliderOptions: {
            min: 25,
            max: 50,
            tooltip: 'always',
        },
        heightValue: [],
        heightSliderOptions: {
            min: 140,
            max: 199,
            tooltip: 'always',
        },
        incomeValue: [],
        incomeSliderOptions: {
            min: 0,
            max: 20,
            tooltip: 'always',
        },
        houseSliderOptions: {
            min: 0,
            max: 50,
            tooltip: 'always',
        },
        jobSliderOptions: {
            min: 0,
            max: 50,
            tooltip: 'always',
        },
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        displayGrid() {
            return ['religion'].includes(this.options.key)
        },
        multiSelect() {
            return ['religion', 'university'].includes(this.options.key)
        },
        getTitle() {
            if (this.options.key === 'age') {
                return '나이 (한국나이)'
            } else if (this.options.key === 'height') {
                return '선호 키'
            } else {
                return '지역'
            }
        },
        getDescription() {
            if (this.options.key === 'age') {
                return '설정한 나이의 회원을 우선적으로 추천합니다.<br> 다른 요소들을 고려해 설정한 나이를 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'height') {
                return '설정한 키의 회원을 우선적으로 추천합니다.<br> 다른 요소들을 고려해 설정한 범위를 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'region') {
                return '설정한 거리의 회원을 우선적으로 추천합니다.<br> 다른 요소들을 고려해 설정한 나이를 벗어난 회원이 추천될 수 있습니다.'
            } else {
                return ''
            }
        },
        getDescriptionSecond() {
            if (this.options.key === 'height') {
                return '너무 높은 키를 설정할 경우 프로필 추천이 어려울 수 있으니, 외모, 직업, 경제력 등 다른 요소들이 마음에 들 경우를 가정해서 선택해주세요.'
            } else {
                return ''
            }
        },
        // rangeTooNarrow() {
        //     if (this.ageValue[1] - this.ageValue[0] < 5) {
        //         return true
        //     } else if (this.heightValue[1] - this.heightValue[0] < 5) {
        //         return true
        //     } else if (this.houseValue < 5 || this.jobValue < 5) {
        //         return true
        //     } else {
        //         return false
        //     }
        // },
        // warnDescription() {
        //     if (this.options.key === 'age') {
        //         return '설정한 나이 범위가 너무 좁습니다. 이 경우, 다른 선호사항을 고려하기가 어려워져요. 최소 5살 정도의 나이 범위를 추천드립니다.'
        //     } else if (this.options.key === 'height') {
        //         return '설정한 키 범위가 너무 좁습니다. 이 경우, 다른 선호사항을 고려하기가 어려워져요. 최소 5cm 이상의 키 범위를 추천드립니다.'
        //     } else if (this.options.key === 'distance' ) {
        //         if (this.houseValue < 5 && this.jobValue > 5) {
        //             return '설정한 거주지 거리 범위가 너무 좁습니다. 이 경우, 다른 선호사항을 고려하기가 어려워져요. 최소 5km 이상의 거리 범위를 추천드립니다.'
        //         } else if (this.jobValue < 5 && this.houseValue > 5) {
        //             return '설정한 직장 거리 범위가 너무 좁습니다. 이 경우, 다른 선호사항을 고려하기가 어려워져요. 최소 5km 이상의 거리 범위를 추천드립니다.'
        //         } else if (this.jobValue < 5 && this.houseValue < 5) {
        //             return '설정한 거주지, 직장 거리 범위가 너무 좁습니다. 이 경우, 다른 선호사항을 고려하기가 어려워져요. 최소 5km 이상의 거리 범위를 추천드립니다.'
        //         } else {
        //             return ''
        //         }
        //     } else {
        //         return ''
        //     }
        // },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            // await this.$store.dispatch('loadMe')
            this.ageValue = [this.options.style.min_age, this.options.style.max_age]
            this.heightValue = [this.options.style.min_height, this.options.style.max_height]
            this.houseValue = this.options.style.house_distance
            this.jobValue = this.options.style.job_distance

            this.ageRange = [this.ageSliderOptions.min, this.ageSliderOptions.max]
            this.heightRange = [this.heightSliderOptions.min, this.heightSliderOptions.max]
            this.houseRange = [this.houseSliderOptions.min, this.houseSliderOptions.max]
            this.jobRange = [this.jobSliderOptions.min, this.jobSliderOptions.max]

            this.mountedAll = true
        },
        updateStyleChanged(newRange) {
            this.styleChanged = true
            if (this.options.key === 'age') {
                this.ageValue = newRange
            } else if (this.options.key === 'height') {
                this.heightValue = newRange
            } else if (this.options.key === 'region') {
                const { value, title } = newRange
                console.log('val:', value, 'title:', title)
                if (title === '내 집으로부터') {
                    this.houseValue = value || 0
                } else {
                    this.jobValue = value || 0
                }
            }
        },
        submit() {
            if (this.options.key === 'age') {
                this.$emit('close', this.ageValue)
            } else if (this.options.key === 'height') {
                this.$emit('close', this.heightValue)
            } else if (this.options.key === 'region') {
                this.$emit('close', { house: this.houseValue, job: this.jobValue })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-edit-style-type {
    overflow-y: hidden;
}
.body {
    padding: 30px 0;
    height: calc(100vh - 56px - 70px);
    overflow-y: auto;
}
.header {
    position: sticky;
    max-height: 52px;
    top: 0;
    background: #faf0e1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 16px;
    // padding: 16px;
}
.title {
    font-size: 18px;
    // text-align: center;
    color: #151360;
}
.slider-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #5c5c77;
}
.description {
    line-height: 24px;
}
.grid-col {
    .item {
        margin: 0 !important;
    }
}
.item {
    // text-align: center;
}

.warn {
    display: flex;
    padding: 12px;
    color: #f64f56;
    border-radius: 8px;
    background: rgba(246, 79, 86, 0.1);
    line-height: 22.4px;
}
</style>
